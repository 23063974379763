var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseAccordionList', {
    attrs: {
      "allow-toggle": false
    }
  }, [_c('BaseAccordionListItem', {
    attrs: {
      "label": _vm.constants.mealPlanValueToLabel[_vm.mealTime],
      "is-active": _vm.isDisabled || _vm.menus.length === 0 ? false : true,
      "is-disabled": _vm.isDisabled
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "background-color": "#FFF",
            "border-radius": "6px",
            "cursor": _vm.isDisabled || !_vm.menus || _vm.menus.length === 0 ? 'not-allowed' : 'pointer'
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onClickMealTime.apply(null, arguments);
            }
          }
        }, [_c('c-checkbox', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                borderColor: 'primary.400'
              },
              'input[type=checkbox]+div svg': {
                width: '15px',
                height: '15px'
              },
              'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {
                color: 'primary.400',
                backgroundColor: 'white'
              }
            },
            expression: "{\n            'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {\n              width: '30px',\n              height: '30px',\n              borderRadius: '4px',\n              borderColor: 'primary.400'\n            },\n            'input[type=checkbox]+div svg': {\n              width: '15px',\n              height: '15px',\n            },\n            'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {\n              color: 'primary.400',\n              backgroundColor: 'white',\n            }\n          }"
          }],
          attrs: {
            "is-checked": _vm.isAllChecked,
            "is-indeterminate": _vm.isIndeterminate,
            "variant-color": "primary",
            "pointer-events": "none"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_vm.menus && _vm.menus.length > 0 ? _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "gap": "8px",
            "padding-block": "8px"
          }
        }, _vm._l(_vm.menus, function (menu, i) {
          var _menu$menus, _menu$menus2, _menu$menus3, _menu$menus4;

          return _c('c-flex', {
            key: i,
            attrs: {
              "align-items": "center",
              "justify-content": "space-between",
              "padding-inline": "12px",
              "background-color": "primary.50",
              "width": "100%",
              "height": "52px",
              "border-bottom": "1px solid #C4C4C4",
              "border-radius": "12px"
            }
          }, [(menu === null || menu === void 0 ? void 0 : menu.menuType) === 'singleEntry' ? _c('c-text', {
            attrs: {
              "color": "primary.400",
              "font-size": "18px",
              "font-weight": "500"
            }
          }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : (_menu$menus = menu.menus) === null || _menu$menus === void 0 ? void 0 : _menu$menus.servingSize) + " " + _vm._s((menu === null || menu === void 0 ? void 0 : (_menu$menus2 = menu.menus) === null || _menu$menus2 === void 0 ? void 0 : _menu$menus2.servingSizeUnit) === 'Lainnya' ? menu === null || menu === void 0 ? void 0 : (_menu$menus3 = menu.menus) === null || _menu$menus3 === void 0 ? void 0 : _menu$menus3.servingSizeUnitOther : menu === null || menu === void 0 ? void 0 : (_menu$menus4 = menu.menus) === null || _menu$menus4 === void 0 ? void 0 : _menu$menus4.servingSizeUnit) + " " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")]) : _c('c-text', {
            attrs: {
              "color": "primary.400",
              "font-size": "18px",
              "font-weight": "500"
            }
          }, [_vm._v(" " + _vm._s(menu === null || menu === void 0 ? void 0 : menu.menuName) + " ")]), _c('c-box', {
            attrs: {
              "background-color": "#FFF",
              "border-radius": "6px"
            }
          }, [_c('c-checkbox', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                'input[type=checkbox]+div': {
                  width: '30px',
                  height: '30px',
                  borderRadius: '6px'
                },
                'input[type=checkbox]+div svg': {
                  width: '15px',
                  height: '15px'
                }
              },
              expression: "{\n                'input[type=checkbox]+div': {\n                  width: '30px',\n                  height: '30px',\n                  borderRadius: '6px',\n                },\n                'input[type=checkbox]+div svg': {\n                  width: '15px',\n                  height: '15px',\n                },\n              }"
            }],
            attrs: {
              "variant-color": "primary",
              "value": "".concat(_vm.mealTime, "-").concat(i),
              "is-checked": _vm.selectedMenus[i] ? _vm.selectedMenus[i] : false
            },
            on: {
              "change": function change(val, $e) {
                return _vm.onClickMenu(i, $e.target.checked);
              }
            }
          })], 1)], 1);
        }), 1) : _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "gap": "8px",
            "padding-block": "8px"
          }
        }, [_c('c-text', {
          attrs: {
            "text-align": "center",
            "color": "danger.400"
          }
        }, [_vm._v(" Belum ada menu yang ditambahkan ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }