<template>
  <BaseAccordionList
    :allow-toggle="false"
  >
    <BaseAccordionListItem
      :label="constants.mealPlanValueToLabel[mealTime]"
      :is-active="isDisabled || menus.length === 0 ? false : true"
      :is-disabled="isDisabled"
    >
      <template #header>
        <c-flex
          background-color="#FFF"
          border-radius="6px"
          :cursor="isDisabled || !menus || menus.length === 0 ? 'not-allowed' : 'pointer'"
          @click.stop="onClickMealTime"
        >
          <c-checkbox
            :is-checked="isAllChecked"
            :is-indeterminate="isIndeterminate"
            variant-color="primary"
            v-chakra="{
              'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {
                width: '30px',
                height: '30px',
                borderRadius: '4px',
                borderColor: 'primary.400'
              },
              'input[type=checkbox]+div svg': {
                width: '15px',
                height: '15px',
              },
              'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {
                color: 'primary.400',
                backgroundColor: 'white',
              }
            }"
            pointer-events="none"
          />
        </c-flex>
      </template>
      <template #default>
        <c-flex
          v-if="menus && menus.length > 0"
          width="100%"
          flex-direction="column"
          gap="8px"
          padding-block="8px"
        >
          <c-flex
            v-for="(menu, i) in menus"
            :key="i"
            align-items="center"
            justify-content="space-between"
            padding-inline="12px"
            background-color="primary.50"
            width="100%"
            height="52px"
            border-bottom="1px solid #C4C4C4"
            border-radius="12px"
          >
            <c-text
              v-if="menu?.menuType === 'singleEntry'"
              color="primary.400"
              font-size="18px"
              font-weight="500"
            >
              {{ menu?.menus?.servingSize }} {{ menu?.menus?.servingSizeUnit === 'Lainnya' ? menu?.menus?.servingSizeUnitOther : menu?.menus?.servingSizeUnit }} {{ menu?.menuName }}
            </c-text>
            <c-text
              v-else
              color="primary.400"
              font-size="18px"
              font-weight="500"
            >
              {{ menu?.menuName }}
            </c-text>
            <c-box
              background-color="#FFF"
              border-radius="6px"
            >
              <c-checkbox 
                variant-color="primary"
                v-chakra="{
                  'input[type=checkbox]+div': {
                    width: '30px',
                    height: '30px',
                    borderRadius: '6px',
                  },
                  'input[type=checkbox]+div svg': {
                    width: '15px',
                    height: '15px',
                  },
                }"
                :value="`${mealTime}-${i}`"
                :is-checked="selectedMenus[i] ? selectedMenus[i] : false"
                @change="(val, $e) => onClickMenu(i, $e.target.checked)"
              />
            </c-box>
          </c-flex>
        </c-flex>
        <c-flex
          v-else
          width="100%"
          flex-direction="column"
          gap="8px"
          padding-block="8px"
        >
          <c-text
            text-align="center"
            color="danger.400"
          >
            Belum ada menu yang ditambahkan
          </c-text>
        </c-flex>
      </template>
    </BaseAccordionListItem>
  </BaseAccordionList>
</template>

<script>
import BaseAccordionList from '@/components/elements/base-accordion-list'
import BaseAccordionListItem from '@/components/elements/base-accordion-list-item'
import { mealPlanValueToLabel } from '@/constants/meal-plans'

export default {
  name: 'AccordionDuplicateMenuRecommendation',
  components: {
    BaseAccordionList,
    BaseAccordionListItem,
  },
  props: {
    mealTime: {
      type: String,
      default: '',
      required: true,
    },
    menus: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    selectedMealTime: {
      type: String,
      default: '',
    },
    selectedMenus: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['meal-time', 'menus'],
  computed: {
    constants() {
      return {
        mealPlanValueToLabel,
      }
    },
    isAllChecked() {
      if (this.selectedMealTime !== this.mealTime) return false
      return this.selectedMenus.length > 0 ? this.selectedMenus?.every((it) => it === true) : false
    },
    isIndeterminate() {
      if (this.selectedMealTime !== this.mealTime) return false
      return this.selectedMenus.length > 0 ? (this.selectedMenus?.some((it) => it === true) && !this.isAllChecked) : false
    },
  },
  methods: {
    onClickMealTime() {
      if (this.isDisabled) return
      if (this.selectedMealTime === '' || this.isIndeterminate) {
        this.$emit('meal-time', this.mealTime)
        this.$emit('menus', this.menus.map(() => {
          return true
        }))
      } else {
        this.$emit('meal-time', '')
        this.$emit('menus', [])
      }
    },
    onClickMenu(index, value) {
      let menus = this.selectedMenus
      if (menus.length === 0) {
        menus = this.menus.map(() => {
          return false
        })
      }

      menus[index] = value
      this.$emit('menus', menus)
      this.$emit('meal-time', this.mealTime)
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CAccordionHeader"] {
  height: 52px;
}

::v-deep [data-chakra-component="CCollapse"]:has([data-chakra-component="CAccordionPanel"]) {
  overflow: unset !important;
}

::v-deep [data-chakra-component="CAccordionPanel"] {
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
</style>