<template>
  <c-box width="100%">
    <c-text
      v-if="!isWithoutLabel"
      :font-size="['14px', '16px']"
      font-weight="400"
      font-family="Roboto"
      padding="0px 12px 4px 0px"
      :color="(isInvalidInput || isInvalidSelect ? '#D32737' : (inputValue && selectValue ? '#008C81' : '#555'))"
    >
      {{ label }}
    </c-text>
    <c-flex>
      <FormInput
        v-chakra="{
          'input': {
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important',
          }
        }"
        :value="inputValue"
        type="text"
        label=""
        :placeholder="inputPlaceholder"
        is-required
        :is-invalid="isInvalidInput"
        :invalid-text="invalidInputText"
        is-without-label
        flex-grow="1"
        :is-disabled="isDisabled"
        @input="$emit('change:input', $event)"
        @blur="$emit('blur:input')"
        :is-without-margin-bottom="true"
      />
      <c-box
        flex-basis="0"
        flex-shrink="0"
        :min-width="customWidthSelect ? customWidthSelect : 'fit-content'"
        transition-duration="300ms"
      >
        <FormSelect
          v-chakra="{
            'select': {
              borderTopLeftRadius: '0px !important',
              borderBottomLeftRadius: '0px !important',
              backgroundColor: '#F2F2F2',
            }
          }"
          :value="selectValue"
          label=""
          :placeholder="selectPlaceholder"
          :options="selectOptions"
          is-required
          :is-disabled="isDisabled"
          :is-invalid="isInvalidSelect"
          :invalid-text="invalidSelectText"
          is-without-label
          :is-without-margin-bottom="isWithoutMarginBottom"
          @input="$emit('change:select', $event)"
          @blur="$emit('blur:select')"
        />
        <c-box :margin-top="isWithoutMarginBottom ? '8px': '0px'">
          <FormInput
            v-if="selectValue === 'Lainnya'"
            v-model="selectOtherValue"
            type="text"
            label=""
            placeholder="Masukkan lainnya"
            is-required
            is-helper
            helper-text-color="#888"
            helper-text-type="info"
            helper-text="Maksimal 20 karakter"
            is-without-label
            :is-invalid="isInvalidSelectOther"
            :invalid-text="invalidSelectOtherText"
            :is-without-margin-bottom="isWithoutMarginBottom"
            @input="$emit('change:select-other', $event)"
            @blur="$emit('blur:select-other')"
          />
        </c-box>
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import FormInput from '@/views/client/kuisioner/forms/form-input-2.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2.vue'

export default {
  name: 'InputTextWithSelect',
  components: { FormSelect, FormInput },
  props: {
    inputValue: {
      type: String,
      default: '',
    },
    isInvalidInput: {
      type: Boolean,
      default: false,
    },
    invalidInputText: {
      type: String,
      default: '',
    },
    isInvalidSelect: {
      type: Boolean,
      default: false,
    },
    invalidSelectText: {
      type: String,
      default: '',
    },
    isInvalidSelectOther: {
      type: Boolean,
      default: false,
    },
    invalidSelectOtherText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isWithoutLabel: {
      type: Boolean,
      default: false,
    },
    selectValue: {
      type: String,
      default: '',
    },
    selectOtherValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    selectPlaceholder: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    customWidthSelect: {
      type: String,
      default: '',
    },
  },
  emits: [
    'blur:input',
    'change:input',
    'blur:select',
    'change:select',
    'blur:select-other',
    'change:select-other',
  ],
}
</script>
