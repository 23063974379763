var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "1000px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['16px', '20px'],
            "font-weight": "700"
          }
        }, [_vm._v(" Tambah Sekaligus ")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$formatForPayload$, _vm$formatForPayload$2, _vm$chipDaysState$dis;

        return [_c('c-flex', {
          attrs: {
            "padding-inline": "16px",
            "gap": "8px",
            "margin-top": "16px"
          }
        }, [_c('c-box', {
          attrs: {
            "width": "100%",
            "max-width": "120px"
          }
        }, [_c('FormSelect', {
          attrs: {
            "value": _vm.menuType,
            "label": "Jenis Menu",
            "placeholder": "Pilih",
            "is-required": "",
            "is-without-margin-bottom": "",
            "is-without-label": "",
            "options": [{
              value: 'singleEntry',
              label: 'Single'
            }, {
              value: 'multipleEntry',
              label: 'Multiple'
            }],
            "is-disabled": false,
            "is-invalid": false,
            "invalid-text": 'xxxxx'
          },
          on: {
            "input": function input(val) {
              _vm.menuType = val;
              _vm.menuName = null;
              _vm.menusArray = [{
                _menuType: val,
                foodName: null,
                servingSize: null,
                servingSizeUnit: null,
                servingSizeUnitOther: null,
                photoUrl: null,
                mealGlossariesId: null
              }];
              _vm.menusObject = {
                _menuType: val,
                servingSize: null,
                servingSizeUnit: null,
                servingSizeUnitOther: null,
                photoUrl: null,
                mealGlossariesId: null
              };
            },
            "blur": function blur() {}
          }
        })], 1), _vm.menuType === 'singleEntry' ? _c('c-flex', {
          attrs: {
            "width": "100%",
            "gap": "8px"
          }
        }, [_c('c-box', {
          attrs: {
            "width": "100%"
          }
        }, [_c('FormInput', {
          attrs: {
            "label": "Nama Menu",
            "placeholder": "Masukkan Nama Menu",
            "is-required": "",
            "is-disabled": false,
            "is-without-margin-bottom": "",
            "is-without-label": ""
          },
          model: {
            value: _vm.menuName,
            callback: function callback($$v) {
              _vm.menuName = $$v;
            },
            expression: "menuName"
          }
        })], 1), _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "max-width": "300px"
          }
        }, [_c('InputTextWithSelect', {
          attrs: {
            "custom-width-select": "130px",
            "label": "Jumlah Takaran",
            "input-placeholder": "Masukkan Jumlah Takaran",
            "select-placeholder": "Pilih",
            "input-value": _vm.menusObject.servingSize,
            "select-value": _vm.menusObject.servingSizeUnit,
            "select-other-value": _vm.menusObject.servingSizeUnitOther,
            "select-options": _vm.optionPortionUnits,
            "is-disabled": false,
            "is-without-margin-bottom": "",
            "is-without-label": ""
          },
          on: {
            "blur:input": function blurInput() {},
            "blur:select": function blurSelect() {},
            "blur:select-other": function blurSelectOther() {},
            "change:input": function changeInput(v_) {
              return _vm.menusObject.servingSize = v_;
            },
            "change:select": function changeSelect(v_) {
              return _vm.menusObject.servingSizeUnit = v_;
            },
            "change:select-other": function changeSelectOther(v_) {
              return _vm.menusObject.servingSizeUnitOther = v_;
            }
          }
        })], 1), _c('c-box', [_c('InputImage2', {
          attrs: {
            "custom-style": {
              width: ['48px', '62px'],
              height: ['48px', '62px']
            },
            "with-bottom-text": false,
            "photo-url": _vm.menusObject.photoUrl,
            "is-disabled": false
          },
          on: {
            "delete:photo": function deletePhoto() {
              _vm.menusObject.photoUrl = null;
              _vm.menusObject.mealGlossariesId = null;
            },
            "click:photo": function clickPhoto() {
              _vm.popupIsOpen = true;
            }
          }
        })], 1)], 1) : _vm._e(), _vm.menuType === 'multipleEntry' ? _c('c-flex', {
          attrs: {
            "width": "100%",
            "gap": "8px",
            "flex-direction": "column"
          }
        }, [_c('c-flex', {
          attrs: {
            "width": "100%",
            "gap": "8px"
          }
        }, [_c('c-box', {
          attrs: {
            "width": "100%"
          }
        }, [_c('FormInput', {
          attrs: {
            "label": "Nama Menu",
            "placeholder": "Masukkan Nama Menu",
            "is-required": "",
            "is-disabled": false,
            "is-without-margin-bottom": "",
            "is-without-label": ""
          },
          model: {
            value: _vm.menuName,
            callback: function callback($$v) {
              _vm.menuName = $$v;
            },
            expression: "menuName"
          }
        })], 1), _c('c-box', {
          attrs: {
            "width": "100%"
          }
        }, [_c('FormInput', {
          attrs: {
            "label": "Komposisi Makanan",
            "placeholder": "Masukkan Komposisi Makanan",
            "is-required": "",
            "is-disabled": false,
            "is-without-margin-bottom": "",
            "is-without-label": ""
          },
          model: {
            value: _vm.menusArray[0].foodName,
            callback: function callback($$v) {
              _vm.$set(_vm.menusArray[0], "foodName", $$v);
            },
            expression: "menusArray[0].foodName"
          }
        })], 1), _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column"
          }
        }, [_c('InputTextWithSelect', {
          attrs: {
            "custom-width-select": "130px",
            "label": "Jumlah Takaran",
            "input-placeholder": "Masukkan Jumlah Takaran",
            "select-placeholder": "Pilih",
            "input-value": _vm.menusArray[0].servingSize,
            "select-value": _vm.menusArray[0].servingSizeUnit,
            "select-other-value": _vm.menusArray[0].servingSizeUnitOther,
            "select-options": _vm.optionPortionUnits,
            "is-disabled": false,
            "is-without-margin-bottom": "",
            "is-without-label": ""
          },
          on: {
            "blur:input": function blurInput() {},
            "blur:select": function blurSelect() {},
            "blur:select-other": function blurSelectOther() {},
            "change:input": function changeInput(v_) {
              return _vm.menusArray[0].servingSize = v_;
            },
            "change:select": function changeSelect(v_) {
              return _vm.menusArray[0].servingSizeUnit = v_;
            },
            "change:select-other": function changeSelectOther(v_) {
              return _vm.menusArray[0].servingSizeUnitOther = v_;
            }
          }
        })], 1), _c('c-box', [_c('InputImage2', {
          attrs: {
            "custom-style": {
              width: ['48px', '62px'],
              height: ['48px', '62px']
            },
            "with-bottom-text": false,
            "photo-url": _vm.menusArray[0].photoUrl,
            "is-disabled": false
          },
          on: {
            "delete:photo": function deletePhoto() {
              _vm.menusArray[0].photoUrl = null;
              _vm.menusArray[0].mealGlossariesId = null;
            },
            "click:photo": function clickPhoto() {
              _vm.popupIsOpen = true;
              _vm.popupItemIndexes = [0];
            }
          }
        })], 1), _vm.menusArray.length >= 2 ? _c('c-box', [_c('c-box', {
          attrs: {
            "width": "52px"
          }
        }, [_vm._v("   ")])], 1) : _vm._e()], 1), _c('TestFieldArray', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var push2 = _ref.push,
                  remove2 = _ref.remove;
              return [_vm._l(_vm.menusArray, function (v2, i2) {
                return i2 >= 1 ? _c('c-flex', {
                  key: i2,
                  attrs: {
                    "margin-top": "8px",
                    "width": "100%",
                    "gap": "8px"
                  }
                }, [_c('c-box', {
                  attrs: {
                    "width": "100%"
                  }
                }), _c('c-box', {
                  attrs: {
                    "width": "100%"
                  }
                }, [_c('FormInput', {
                  attrs: {
                    "label": "Komposisi Makanan",
                    "placeholder": "Masukkan Komposisi Makanan",
                    "is-required": "",
                    "is-disabled": false,
                    "is-without-margin-bottom": "",
                    "is-without-label": ""
                  },
                  model: {
                    value: v2.foodName,
                    callback: function callback($$v) {
                      _vm.$set(v2, "foodName", $$v);
                    },
                    expression: "v2.foodName"
                  }
                })], 1), _c('c-flex', {
                  attrs: {
                    "width": "100%",
                    "flex-direction": "column"
                  }
                }, [_c('InputTextWithSelect', {
                  attrs: {
                    "custom-width-select": "130px",
                    "label": "Jumlah Takaran",
                    "input-placeholder": "Masukkan Jumlah Takaran",
                    "select-placeholder": "Pilih",
                    "input-value": v2.servingSize,
                    "select-value": v2.servingSizeUnit,
                    "select-other-value": v2.servingSizeUnitOther,
                    "select-options": _vm.optionPortionUnits,
                    "is-disabled": false,
                    "is-without-margin-bottom": "",
                    "is-without-label": ""
                  },
                  on: {
                    "blur:input": function blurInput() {},
                    "blur:select": function blurSelect() {},
                    "blur:select-other": function blurSelectOther() {},
                    "change:input": function changeInput(v_) {
                      return v2.servingSize = v_;
                    },
                    "change:select": function changeSelect(v_) {
                      return v2.servingSizeUnit = v_;
                    },
                    "change:select-other": function changeSelectOther(v_) {
                      return v2.servingSizeUnitOther = v_;
                    }
                  }
                })], 1), _c('c-box', [_c('InputImage2', {
                  attrs: {
                    "custom-style": {
                      width: ['48px', '62px'],
                      height: ['48px', '62px']
                    },
                    "with-bottom-text": false,
                    "photo-url": v2.photoUrl,
                    "is-disabled": false,
                    "is-without-margin-bottom": ""
                  },
                  on: {
                    "delete:photo": function deletePhoto() {
                      v2.photoUrl = null;
                      v2.mealGlossariesId = null;
                    },
                    "click:photo": function clickPhoto() {
                      _vm.popupIsOpen = true;
                      _vm.popupItemIndexes = [i2];
                    }
                  }
                })], 1), _c('c-flex', {
                  attrs: {
                    "margin-bottom": "8px"
                  }
                }, [_c('c-flex', {
                  attrs: {
                    "align-items": "center"
                  }
                }, [_c('c-button', {
                  attrs: {
                    "background-color": "transparent",
                    "_hover": {
                      backgroundColor: 'neutral.lightGray'
                    },
                    "margin-left": "auto"
                  },
                  on: {
                    "click": function click($event) {
                      return remove2(_vm.menusArray, i2);
                    }
                  }
                }, [_c('c-box', {
                  attrs: {
                    "height": "20px",
                    "width": "20px"
                  }
                }, [_c('inline-svg', {
                  attrs: {
                    "src": require('@/assets/icons/icon-trash.svg'),
                    "height": "20",
                    "width": "20",
                    "fill": "#D32737"
                  }
                })], 1)], 1)], 1)], 1)], 1) : _vm._e();
              }), _c('c-flex', {
                attrs: {
                  "justify-content": "flex-end",
                  "margin-top": "8px"
                }
              }, [_c('BaseButton', {
                attrs: {
                  "size": "medium",
                  "border-radius": "1000px",
                  "variant": "outlined",
                  "width": "70%",
                  "background-color": "white",
                  "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
                  "left-svg-icon-color": "#008C81"
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return push2(_vm.menusArray, {
                      _menuType: _vm.menuType,
                      foodName: null,
                      servingSize: null,
                      servingSizeUnit: null,
                      servingSizeUnitOther: null,
                      photoUrl: null,
                      mealGlossariesId: null
                    });
                  }
                }
              }, [_vm._v(" Tambah Komposisi Makanan ")])], 1)];
            }
          }], null, false, 3072466211)
        })], 1) : _vm._e()], 1), _c('c-box', {
          attrs: {
            "margin-top": "16px",
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['14px', '16px'],
            "color": "primary.400",
            "px": "16px"
          }
        }, [_vm._v(" Tambah menu ke hari ")]), _c('c-box', {
          attrs: {
            "margin-top": ['8px', '14px']
          }
        }, [_c('ChipDays', {
          attrs: {
            "start-at": _vm.currentActiveDays[0],
            "end-at": _vm.currentActiveDays[_vm.currentActiveDays.length - 1],
            "selected-day": _vm.selectedDay,
            "invalid-day-validation": _vm.chipDaysState.invalidDayValidation,
            "custom-item-min-width": "70px",
            "disabled-day": _vm.chipDaysState.disabledDay,
            "days-state": (_vm$formatForPayload$ = _vm.formatForPayload.data) === null || _vm$formatForPayload$ === void 0 ? void 0 : (_vm$formatForPayload$2 = _vm$formatForPayload$.destination) === null || _vm$formatForPayload$2 === void 0 ? void 0 : _vm$formatForPayload$2.reduce(function (acc, curr) {
              acc[curr.day] = 'haha';
              return acc;
            }, {}),
            "custom-item-width": "100%",
            "custom-template-columns": "repeat(10, 1fr)"
          },
          on: {
            "on-change-day": function onChangeDay(day) {
              return _vm.selectedDay = day;
            }
          }
        }), !!_vm.selectedDay && !((_vm$chipDaysState$dis = _vm.chipDaysState.disabledDay) !== null && _vm$chipDaysState$dis !== void 0 && _vm$chipDaysState$dis.some(function (v) {
          return v === _vm.selectedDay;
        })) ? _c('c-box', {
          attrs: {
            "margin-top": "16px",
            "as": "ul",
            "padding-inline": "16px",
            "list-style-type": "none",
            "display": "flex",
            "flex-direction": "column",
            "gap": "16px"
          }
        }, _vm._l(_vm.listMenuCategory, function (v) {
          var _vm$listChecked, _vm$listChecked$_vm$s;

          return _c('c-box', {
            key: v.id,
            attrs: {
              "as": "li"
            }
          }, [_c('c-flex', {
            attrs: {
              "background-color": "primary.400",
              "color": "white",
              "height": ['45px', '52px'],
              "font-weight": "500",
              "font-size": ['16px', '18px'],
              "padding-inline": "16px",
              "padding-block": "8px",
              "align-items": "center",
              "border-radius": "12px",
              "justify-content": "space-between"
            }
          }, [_c('span', [_vm._v(" " + _vm._s(v.label) + " ")]), _c('c-checkbox', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                '&  div': {
                  width: '30px',
                  height: '30px'
                },
                '&  svg': {
                  width: '15px',
                  height: '15px'
                },
                '& :not(input[type=checkbox]:checked) + div': {
                  backgroundColor: 'white',
                  borderColor: 'white',
                  color: 'white'
                },
                '& input[type=checkbox]:checked + div': {
                  backgroundColor: 'primary.50',
                  borderColor: 'primary.50',
                  color: 'primary.500'
                }
              },
              expression: "{\n                    '&  div': {\n                      width: '30px',\n                      height: '30px',\n                    },\n                    '&  svg': {\n                      width: '15px',\n                      height: '15px',\n                    },\n                    '& :not(input[type=checkbox]:checked) + div': {\n                      backgroundColor: 'white',\n                      borderColor: 'white',\n                      color: 'white',\n                    },\n                    '& input[type=checkbox]:checked + div' : {\n                      backgroundColor: 'primary.50',\n                      borderColor: 'primary.50',\n                      color: 'primary.500',\n                    },\n                  }"
            }],
            attrs: {
              "is-checked": (_vm$listChecked = _vm.listChecked) === null || _vm$listChecked === void 0 ? void 0 : (_vm$listChecked$_vm$s = _vm$listChecked[_vm.selectedDay]) === null || _vm$listChecked$_vm$s === void 0 ? void 0 : _vm$listChecked$_vm$s[v.id]
            },
            on: {
              "change": function change(_, $e) {
                return _vm.handleCheckbox(v.id, $e);
              }
            }
          })], 1)], 1);
        }), 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "16px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-button', {
          attrs: {
            "font-size": "18px",
            "font-weight": "500",
            "variant-color": "primary",
            "border-radius": "calc(infinity * 1px)",
            "variant": "outline",
            "width": "100%",
            "size": "lg"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Batal ")]), _c('c-button', {
          attrs: {
            "font-size": "18px",
            "font-weight": "500",
            "variant-color": "secondary",
            "color": "primary.400",
            "background-color": "secondary.400",
            "border-radius": "calc(infinity * 1px)",
            "width": "100%",
            "size": "lg",
            "disabled": _vm.formatForPayload.zodParse.success === false || _vm.isSaving,
            "_disabled": {
              backgroundColor: 'neutral.lightGray',
              color: 'neutral.white',
              cursor: 'not-allowed'
            }
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalChooseMenu', {
    attrs: {
      "is-open": _vm.popupIsOpen,
      "title": "Pilih Tampilan",
      "is-loading-data-food": _vm.popupIsLoadingDataFood,
      "options": _vm.popupOptions,
      "selected-foods": _vm.popupSelectedMenu,
      "pages": _vm.popupPages,
      "filter": _vm.popupFilter
    },
    on: {
      "change:selected-food": function changeSelectedFood(menu) {
        var _vm$menusArray, _vm$popupItemIndexes;

        if ((_vm$menusArray = _vm.menusArray) !== null && _vm$menusArray !== void 0 && _vm$menusArray[(_vm$popupItemIndexes = _vm.popupItemIndexes) === null || _vm$popupItemIndexes === void 0 ? void 0 : _vm$popupItemIndexes[0]]) {
          _vm.menusArray[_vm.popupItemIndexes[0]].photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.menusArray[_vm.popupItemIndexes[0]].mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        } else if (_vm.menusObject) {
          _vm.menusObject.photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.menusObject.mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        }

        _vm.popupIsOpen = false;
      },
      "change:is-open": function changeIsOpen(open) {
        return _vm.popupIsOpen = open;
      },
      "change:filter-page": function changeFilterPage(page) {
        return _vm.popupFilter.page = page;
      },
      "change:filter-search": function changeFilterSearch(search) {
        _vm.popupFilter.page = 1;
        _vm.popupFilter.search = search;
      }
    }
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Tambah sekaligus jam makan berhasil"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }