var render = function () {
  var _vm$customStyle, _vm$customStyle2, _vm$customStyle3, _vm$customStyle4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "w": "full",
      "is-invalid": _vm.isInvalid
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "full",
      "position": "relative",
      "flex-direction": "column"
    }
  }, [!!_vm.photoUrl && !_vm.isDisabled ? _c('c-button', {
    attrs: {
      "top": "6px",
      "right": "6px",
      "position": "absolute",
      "variant": "ghost",
      "m": "0",
      "p": "0",
      "min-w": "24px",
      "min-h": "24px",
      "h": "24px",
      "w": "24px",
      "border-radius": "50%"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('delete:photo');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-close-black.svg'),
      "alt": "icon close",
      "h": "24px",
      "w": "24px"
    }
  })], 1) : _vm._e(), _c('c-box', {
    attrs: {
      "as": "label",
      "width": (_vm$customStyle = _vm.customStyle) !== null && _vm$customStyle !== void 0 && _vm$customStyle.width ? (_vm$customStyle2 = _vm.customStyle) === null || _vm$customStyle2 === void 0 ? void 0 : _vm$customStyle2.width : '150px',
      "height": (_vm$customStyle3 = _vm.customStyle) !== null && _vm$customStyle3 !== void 0 && _vm$customStyle3.height ? (_vm$customStyle4 = _vm.customStyle) === null || _vm$customStyle4 === void 0 ? void 0 : _vm$customStyle4.height : '120px',
      "border-top-left-radius": "8px",
      "border-top-right-radius": "8px",
      "overflow": "hidden",
      "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer',
      "background-color": "white"
    },
    on: {
      "click": function click($event) {
        _vm.isDisabled ? null : _vm.$emit('click:photo', $event);
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.photoUrl ? _vm.getPreviewImage(_vm.photoUrl) : require('@/assets/icons/icon-image-preview-green.svg'),
      "alt": "img program",
      "width": "100%",
      "height": "100%",
      "object-fit": "cover",
      "opacity": _vm.isDisabled ? '0.5' : '1'
    }
  })], 1), _vm.withBottomText ? _c('c-flex', {
    attrs: {
      "width": "150px",
      "background-color": "neutral.888888",
      "border-bottom-left-radius": "8px",
      "border-bottom-right-radius": "8px",
      "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer'
    },
    on: {
      "click": function click($event) {
        _vm.isDisabled ? null : _vm.$emit('click:photo', $event);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "text-align": "center",
      "color": "white",
      "font-size": "14px",
      "padding": "4px 8px",
      "line-height": "1.2"
    }
  }, [_vm._v(" Tambah tampilan dari nilai gizi ")])], 1) : _vm._e()], 1), _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Harus diisi ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }