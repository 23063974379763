<template>
  <c-box>
    <BaseButton
      variant="outlined"
      :is-disabled="!isValidAllForm || isDisabled"
      @click="onOpenDuplicate"
    >
      Duplikasi
    </BaseButton>
    <BaseModal
      :is-open="isOpenModalDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
      size="1150px"
      overflow="hidden"
    >
      <template #header>
        <c-flex
          width="100%"
          justify-content="center"
          padding-block="10px"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
          >
            Duplikasi Menu
          </BaseText>
        </c-flex>
      </template>
      <template #body>
        <c-grid
          width="100%"
          padding-inline="16px"
          grid-template-columns="calc(50% - 25px) 50px calc(50% - 25px)" 
        >
          <c-flex
            width="100%"
            flex-direction="column"
            :padding-top="['10px', '12px']"
            border-radius="10px"
            border-width="1px"
            border-style="solid"
            border-color="primary.400"
            gap="8px"
          >
            <c-text
              color="primary.400"
              font-size="16px"
              font-weight="500"
              padding-inline="12px"
            >
              Asal Duplikasi
            </c-text>

            <c-box
              padding-inline="12px"
            >
              <ChipDays 
                :start-at="1"
                :end-at="10"
                :selected-day="sourceDay"
                custom-template-columns="repeat(10, 1fr)"
                custom-item-min-width="36px"
                @on-change-day="(day) => {
                  if (day != sourceDay) {
                    sourceDay = day
                  }
                }"
              />
            </c-box>

            <c-flex
              v-if="isLoadingData"
              width="100%"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              padding="128px 24px"
            >
              <c-spinner
                thickness="4px"
                speed="0.65s"
                empty-color="green.200"
                color="primary.400"
                size="xl"
                margin-bottom="16px"
              />
              <c-text
                color="primary.400"
                font-weight="500"
              >
                Memuat data...
              </c-text>
            </c-flex>
            <c-box
              v-else-if="menuRecommendations"
              width="100%"
              max-height="350px"
              overflow-y="auto"
              padding-inline="12px"
              padding-block="6px"
            >
              <c-box
                v-for="mealTime in mealTimeStates_"
                :key="mealTime.key"
              >
                <AccordionDuplicateMenuRecommendation 
                  :meal-time="mealTime?.key"
                  :menus="menuRecommendations[mealTime?.key] ? menuRecommendations[mealTime?.key]?.dataMenu : []"
                  :is-disabled="checkIsDisabledSourceMealTime(mealTime.key)"
                  :selected-meal-time="formDuplicate.sourceMenu.mealTime"
                  :selected-menus="formDuplicate.sourceMenu.selectedMenus"
                  @meal-time="(val) => formDuplicate.sourceMenu.mealTime = val"
                  @menus="(val) => onUpdateSelectedMenus(val)"
                />
              </c-box>
            </c-box>
          </c-flex>
          <c-flex
            width="100%"
            justify-content="center"
            align-items="center"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-double-arrow.svg')"
              height="34px"
              width="29px"
            />
          </c-flex>
          <c-flex
            width="100%"
            flex-direction="column"
            :padding-block="['10px', '12px']"
            border-radius="10px"
            border-width="1px"
            border-style="solid"
            border-color="primary.400"
            gap="8px"
          >
            <c-text
              color="primary.400"
              font-size="16px"
              font-weight="500"
              padding-inline="12px"
            >
              Tujuan Duplikasi
            </c-text>

            <c-box
              padding-inline="12px"
            >
              <ChipDays 
                :start-at="1"
                :end-at="10"
                :selected-day="destinationDay"
                custom-template-columns="repeat(10, 1fr)"
                custom-item-min-width="36px"
                :checked-days="checkedDestinationDays"
                @on-change-day="(day) => onChangeDestinationDay(day)"
              />
            </c-box>

            <c-box
              width="100%"
              max-height="350px"
              overflow-y="auto"
              padding-inline="12px"
            >
              <c-flex
                width="100%"
                flex-direction="column"
                gap="16px"
              >
                <c-flex
                  v-for="mealTime in mealTimeStates_"
                  :key="mealTime.key"
                  justify-content="space-between"
                  align-items="center"
                  height="52px"
                  background-color="primary.400"
                  padding="8px 16px"
                  border-radius="8px"
                  cursor="pointer"
                  :_hover="{
                    backgroundColor: 'primary.500',
                  }"
                  @click="onClickDestinationMealTime(mealTime.key, destinationDay)"
                >
                  <c-text
                    font-size="18px"
                    font-weight="500"
                    color="#FFF"
                  >
                    {{ constants.mealPlanValueToLabel[mealTime.key] }}
                  </c-text>
                  <c-flex
                    background-color="#FFF"
                    border-radius="6px"
                  >
                    <c-checkbox
                      :is-checked="checkIsCheckedDestinationMealTime(mealTime.key, destinationDay)"
                      variant-color="primary"
                      v-chakra="{
                        'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {
                          width: '30px',
                          height: '30px',
                          borderRadius: '4px',
                          borderColor: 'primary.400'
                        },
                        'input[type=checkbox]+div svg': {
                          width: '15px',
                          height: '15px',
                        },
                        'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {
                          color: 'primary.400',
                          backgroundColor: 'white',
                        }
                      }"
                      pointer-events="none"
                    />
                  </c-flex>
                </c-flex>
              </c-flex>
            </c-box>
          </c-flex>
        </c-grid>
      </template>
      <template #footer>
        <c-grid
          width="100%"
          padding-inline="16px"
          padding-block="10px"
          grid-template-columns="calc(50% - 25px) 50px calc(50% - 25px)" 
        >
          <BaseButton
            variant="outlined"
            :is-disabled="isLoadingSubmit"
            @click="onCloseDuplicate"
          >
            Batal
          </BaseButton>
          <c-box />
          <BaseButton
            color="secondary"
            :is-disabled="isDisabledSubmit || isLoadingData"
            :is-loading="isLoadingSubmit"
            @click="onSubmitDuplicate"
          >
            <c-text 
              color="primary.400"
            >
              Simpan
            </c-text>
          </BaseButton>
        </c-grid>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="!!isOpenErrorDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-warning.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            margin-top="8px"
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Duplikasi jam makan gagal
          </BaseText>
          <BaseText
            v-if="errorMessage"
            margin-top="16px"
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            {{ errorMessage }}
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px auto"
          text-align="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="() => {
              onCloseDuplicate()
              isOpenErrorDuplicate = false
            }"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="!!isOpenSuccessDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="150px"
            object-fit="cover"
            :src="require('@/assets/images/success-image.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            margin-top="8px"
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Duplikasi jam makan berhasil
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px auto"
          text-align="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="() => {
              onCloseDuplicate()
              isOpenSuccessDuplicate = false
            }"
          >
            Oke
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  reqMenuRecommendation_Create,
  reqMenuRecommendation_GetDraft,
} from '@/requests/dietela-api/other'
import ChipDays from '@/components/chip-days.vue'
import { mealPlanValueToLabel } from '@/constants/meal-plans'
import AccordionDuplicateMenuRecommendation from '@/components/meal-plan/_widgets/accordion-duplicate-menu-recommendation.vue'

export default {
  name: 'ModalDuplicateMenuInMenuRecommendation',
  components: {
    BaseButton,
    BaseText,
    BaseModal,
    ChipDays,
    AccordionDuplicateMenuRecommendation,
  },
  props: {
    isValidAllForm: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    mealTimeStates: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sourceDay: 0,
      destinationDay: 1,
      formDuplicate: {
        sourceMenu: {
          mealTime: '',
          selectedMenus: [],
        },
        destination: [],
      },
      isOpenModalDuplicate: false,
      isLoadingData: false,
      isLoadingSubmit: false,
      isOpenSuccessDuplicate: false,
      isOpenErrorDuplicate: false,
      errorMessage: '',
      menuRecommendations: null,
    }
  },
  computed: {
    constants() {
      return {
        mealPlanValueToLabel,
      }
    },
    day() {
      return this.$route.query.day ?? 1
    },
    isDisabledSubmit() {
      return !this.formDuplicate.sourceMenu.mealTime || !this.formDuplicate.sourceMenu.selectedMenus.some((it) => it === true) || this.formDuplicate.destination.length === 0
    },
    mealTimeStates_() {
      if (!this.mealTimeStates) return []
      return Object
        .keys(this.mealTimeStates)
        .filter(key => !this.mealTimeStates[key].isSkipped)
        .map(key => ({
          key: key,
          isSkipped: this.mealTimeStates[key].isSkipped,
        }))
    },
    checkedDestinationDays() {
      return this.formDuplicate.destination.map((it) => (it.day))
    },
  },
  watch: {
    sourceDay: {
      handler() {
        this.formDuplicate.sourceMenu.mealTime = ''
        this.formDuplicate.sourceMenu.selectedMenus = []
        this.getMenuRecommendationByDay()
      },
    },
  },
  methods: {
    onOpenDuplicate() {
      if (!this.isValidAllForm || this.isDisabled) return
      this.sourceDay = Number(this.day)
      this.destinationDay = 1
      this.isOpenModalDuplicate = true
    },
    onCloseDuplicate() {
      if (!this.isLoadingSubmit) {
        this.isOpenModalDuplicate = false
        this.sourceDay = 0
        this.destinationDay = 1
        this.formDuplicate.sourceMenu.mealTime = ''
        this.formDuplicate.sourceMenu.selectedMenus = []
        this.formDuplicate.destination = []
      }
    },
    async getMenuRecommendationByDay() {
      this.isLoadingData = true
      try {
        this.menuRecommendations = null
        const resDraft = await reqMenuRecommendation_GetDraft(this.$store.getters.axios, {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          day: this.sourceDay,
        })
        const dataDraft = resDraft.data?.data
        if (dataDraft) {
          this.menuRecommendations = dataDraft
        }
      } catch(err) {
        // 
      } finally {
        this.isLoadingData = false
      }
    },
    async onSubmitDuplicate() {
      if (!this.isLoadingData && !this.isLoadingSubmit) {
        try {
          this.isLoadingSubmit = true      
          const dataMenus = this.menuRecommendations[this.formDuplicate.sourceMenu.mealTime]?.dataMenu
          const selectedSourceMenu = dataMenus.filter((_, index) => this.formDuplicate.sourceMenu.selectedMenus[index])
          const payload = {
            clientId: this.$route.params.clientId,
            programId: this.$route.params.programId,
            month: this.$route.params.month,
            sourceMenu: selectedSourceMenu,
            destination: this.formDuplicate.destination,
          }
          await reqNutriMealPlan.putMenuRecommendationDuplication(this.$store.getters.axios, { payload })

          for (let destinationDay of this.formDuplicate.destination) {
            const resDraft = await reqMenuRecommendation_GetDraft(this.$store.getters.axios, {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              month: this.$route.params.month,
              day: destinationDay.day,
            })
            const destinationDraft = resDraft.data?.data
            let destinationDraftValidation = destinationDraft?.validation
            if (destinationDraftValidation === -1) {
              const allMealsValid = this.hasAllMealsTimeValid(destinationDraft)
              if (allMealsValid) {
                let payloadSetValidation = {
                  clientId: this.$route.params.clientId,
                  programId: this.$route.params.programId,
                  month: this.$route.params.month,
                  isDraft: 1,
                  isSaveDraf: 0,
                  day: destinationDay.day,
                  validation: 1,
                }
                await reqMenuRecommendation_Create(this.$store.getters.axios, payloadSetValidation)
              }
            }
          }
          this.$emit('fetch-form-days')

          this.isOpenSuccessDuplicate = true
        } catch (err) {
          this.isOpenErrorDuplicate = true
          this.errorMessage = err?.response?.data?.message
        } finally {
          this.isLoadingSubmit = false
        }
      }
    },
    hasAllMealsTimeValid(data) {
      for (const mealTime of Object.keys(this.mealTimeStates)) {
        if (!data[mealTime] || data[mealTime]?.validation !== 1) {
          return false
        }
      }
      return true
    },
    onUpdateSelectedMenus(val) {
      this.formDuplicate.sourceMenu.selectedMenus = val.map((it) => {
        return it
      })
    },
    checkIsDisabledSourceMealTime(mealTime) {
      if (this.formDuplicate.sourceMenu.mealTime === mealTime || this.formDuplicate.sourceMenu.mealTime === '') {
        return false
      }

      if (this.formDuplicate.sourceMenu.selectedMenus.length === 0 || this.formDuplicate.sourceMenu.selectedMenus.every((it) => it === false)) {
        return false
      }
      return true
    },
    onChangeDestinationDay(day) {
      if (day != this.destinationDay) {
        this.destinationDay = day
      }
    },
    onClickDestinationMealTime(mealTime, day) {
      const updatedDestination = [...this.formDuplicate.destination]
      const selectedDestinationIndex = updatedDestination.findIndex((it) => it?.day === day)

      if (selectedDestinationIndex !== -1) {
        const selectedDestination = updatedDestination[selectedDestinationIndex]
        const isChecked = this.checkIsCheckedDestinationMealTime(mealTime, day)

        if (isChecked) {
          // If already checked, remove the mealTime
          selectedDestination.mealTime = selectedDestination.mealTime.filter((it) => it !== mealTime)
      
          // If no mealTime left, remove the day entirely
          if (selectedDestination.mealTime.length === 0) {
            updatedDestination.splice(selectedDestinationIndex, 1)
          }
        } else {
          // If not checked, add the mealTime
          selectedDestination.mealTime.push(mealTime)
        }
      } else {
        // If no entry exists for the day, create one with the mealTime
        updatedDestination.push({
          day,
          mealTime: [mealTime],
        })
      }

      this.formDuplicate.destination = updatedDestination
    },
    checkIsCheckedDestinationMealTime(mealTime, day) {
      const destinationDay = this.formDuplicate.destination.find((it) => it?.day === day)
      if (destinationDay) {
        return destinationDay?.mealTime?.some((it) => it === mealTime)
      }
      return false
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CAccordion"] {
  margin-bottom: 16px !important;
  gap: 16px;
}
</style>