<template>
  <c-accordion-item
    border="none"
    :is-open="defaultIsOpen ? undefined : isOpen"
    :default-is-open="defaultIsOpen"
    @change="changeAccordion"
  >
    <c-accordion-header
      :_hover="{
        bg: isDisabled ? 'neutral.lightGray' : (isError ? 'danger.500' : 'primary.500'),
      }"
      rounded="8px"
      :background-color="isDisabled ? 'neutral.lightGray' : (isError ? 'danger.400' : 'primary.400')"
      :h="['48px', '62px']"
      :cursor="isDisabled ? 'not-allowed' : 'pointer'"
    >
      <c-box
        flex="1"
        text-align="left"
        color="neutral.white"
        font-family="Roboto, sans-serif"
        font-weight="500"
        :font-size="['14px', '18px']"
      >
        {{ label }}
      </c-box>
      <c-flex
        align-items="center"
      >
        <slot name="header" />
        <c-accordion-icon
          size="32px"
          name="chevron-down"
          color="#FFF"
        />
      </c-flex>
    </c-accordion-header>
    <c-accordion-panel pb="2">
      <slot />
    </c-accordion-panel>
  </c-accordion-item>
</template>

<script>
export default {
  name: 'FormAccordionListItem',
  inject: ['accordion', 'setIsOpenAccordionItem', 'registerAccordionItemId'],
  props: {
    label: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    defaultIsOpen: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      init: true,
    }
  },
  computed: {
    isOpen() {
      // return false
      return this.accordion?.accordion_?.[this._uid]
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler(val) {
        // if active and still open = false
        if (val === true && !this.isOpen) {
          if (this.init) {
            setTimeout(() => {
              this.toggleAccordion(true)
            }, 500)
          } else {
            this.toggleAccordion(true)
          }
        // if not active and open = true
        } else if (val === false && this.isOpen) {
          this.toggleAccordion(false)
        }

        if (this.init) {
          this.init = false
        }
      },
    },
  },
  created() {
    this.registerAccordionItemId(this._uid, true)
  },
  methods: {
    toggleAccordion(value) {
      this.setIsOpenAccordionItem(this._uid, value)
    },
    changeAccordion(value) {
      if (!this.isDisabled) {
        this.toggleAccordion(value)
      }
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CCollapse"] {
  position: relative;
}
</style>