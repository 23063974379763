var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-accordion', {
    attrs: {
      "allow-toggle": true,
      "allow-multiple": false,
      "d": "flex",
      "flex-direction": "column",
      "gap": ['16px', '20px'],
      "mb": ['16px', '20px'],
      "position": "relative"
    }
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }