<template>
  <c-form-control
    w="full"
    :is-invalid="isInvalid"
  >
    <c-flex
      w="full"
      position="relative"
      flex-direction="column"
    >
      <c-button
        v-if="!!photoUrl && !isDisabled"
        top="6px"
        right="6px"
        position="absolute"
        variant="ghost"
        m="0"
        p="0"
        min-w="24px"
        min-h="24px"
        h="24px"
        w="24px"
        border-radius="50%"
        @click="$emit('delete:photo')"
      >
        <c-image
          :src="require('@/assets/icon-close-black.svg')"
          alt="icon close"
          h="24px"
          w="24px"
        />
      </c-button>
      <c-box
        as="label"
        :width="customStyle?.width ? customStyle?.width : '150px'"
        :height="customStyle?.height ? customStyle?.height : '120px'"
        border-top-left-radius="8px"
        border-top-right-radius="8px"
        overflow="hidden"
        :cursor="isDisabled ? 'not-allowed' : 'pointer'"
        background-color="white"
        @click="isDisabled ? null : $emit('click:photo', $event)"
      >
        <!--                        :border-color="$v.photoUrl.$invalid ? '#e66673' : '#c4c4c4'"-->
        <c-image
          :src="photoUrl ? getPreviewImage(photoUrl) : require('@/assets/icons/icon-image-preview-green.svg')"
          alt="img program"
          width="100%"
          height="100%"
          object-fit="cover"
          :opacity="isDisabled ? '0.5' : '1'"
        />
      </c-box>
      <c-flex
        v-if="withBottomText"
        width="150px"
        background-color="neutral.888888"
        border-bottom-left-radius="8px"
        border-bottom-right-radius="8px"
        :cursor="isDisabled ? 'not-allowed' : 'pointer'"
        @click="isDisabled ? null : $emit('click:photo', $event)"
      >
        <c-text
          text-align="center"
          color="white"
          font-size="14px"
          padding="4px 8px"
          line-height="1.2"
        >
          Tambah tampilan dari nilai gizi
        </c-text>
      </c-flex>
    </c-flex>
    <c-form-helper-text
      v-if="isInvalid"
      color="red.500"
    >
      Harus diisi
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { CBox, CButton, CFlex } from '@chakra-ui/vue'

export default {
  name: 'InputImage2',
  components: { CBox, CButton, CFlex },
  props: {
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    photoUrl: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    withBottomText: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'click:photo',
    'delete:photo',
  ],
  methods: {
    getPreviewImage(url) {
      if (typeof this.photoUrl === 'object') {
        return URL.createObjectURL(this.photoUrl)
      }
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
  },
}
</script>
