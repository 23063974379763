var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "is-disabled": !_vm.isValidAllForm || _vm.isDisabled
    },
    on: {
      "click": _vm.onOpenDuplicate
    }
  }, [_vm._v(" Duplikasi ")]), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "1150px",
      "overflow": "hidden"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "center",
            "padding-block": "10px"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px"
          }
        }, [_vm._v(" Duplikasi Menu ")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-grid', {
          attrs: {
            "width": "100%",
            "padding-inline": "16px",
            "grid-template-columns": "calc(50% - 25px) 50px calc(50% - 25px)"
          }
        }, [_c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "padding-top": ['10px', '12px'],
            "border-radius": "10px",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "primary.400",
            "gap": "8px"
          }
        }, [_c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-size": "16px",
            "font-weight": "500",
            "padding-inline": "12px"
          }
        }, [_vm._v(" Asal Duplikasi ")]), _c('c-box', {
          attrs: {
            "padding-inline": "12px"
          }
        }, [_c('ChipDays', {
          attrs: {
            "start-at": 1,
            "end-at": 10,
            "selected-day": _vm.sourceDay,
            "custom-template-columns": "repeat(10, 1fr)",
            "custom-item-min-width": "36px"
          },
          on: {
            "on-change-day": function onChangeDay(day) {
              if (day != _vm.sourceDay) {
                _vm.sourceDay = day;
              }
            }
          }
        })], 1), _vm.isLoadingData ? _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "center",
            "padding": "128px 24px"
          }
        }, [_c('c-spinner', {
          attrs: {
            "thickness": "4px",
            "speed": "0.65s",
            "empty-color": "green.200",
            "color": "primary.400",
            "size": "xl",
            "margin-bottom": "16px"
          }
        }), _c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-weight": "500"
          }
        }, [_vm._v(" Memuat data... ")])], 1) : _vm.menuRecommendations ? _c('c-box', {
          attrs: {
            "width": "100%",
            "max-height": "350px",
            "overflow-y": "auto",
            "padding-inline": "12px",
            "padding-block": "6px"
          }
        }, _vm._l(_vm.mealTimeStates_, function (mealTime) {
          var _vm$menuRecommendatio;

          return _c('c-box', {
            key: mealTime.key
          }, [_c('AccordionDuplicateMenuRecommendation', {
            attrs: {
              "meal-time": mealTime === null || mealTime === void 0 ? void 0 : mealTime.key,
              "menus": _vm.menuRecommendations[mealTime === null || mealTime === void 0 ? void 0 : mealTime.key] ? (_vm$menuRecommendatio = _vm.menuRecommendations[mealTime === null || mealTime === void 0 ? void 0 : mealTime.key]) === null || _vm$menuRecommendatio === void 0 ? void 0 : _vm$menuRecommendatio.dataMenu : [],
              "is-disabled": _vm.checkIsDisabledSourceMealTime(mealTime.key),
              "selected-meal-time": _vm.formDuplicate.sourceMenu.mealTime,
              "selected-menus": _vm.formDuplicate.sourceMenu.selectedMenus
            },
            on: {
              "meal-time": function mealTime(val) {
                return _vm.formDuplicate.sourceMenu.mealTime = val;
              },
              "menus": function menus(val) {
                return _vm.onUpdateSelectedMenus(val);
              }
            }
          })], 1);
        }), 1) : _vm._e()], 1), _c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-double-arrow.svg'),
            "height": "34px",
            "width": "29px"
          }
        })], 1), _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "padding-block": ['10px', '12px'],
            "border-radius": "10px",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": "primary.400",
            "gap": "8px"
          }
        }, [_c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-size": "16px",
            "font-weight": "500",
            "padding-inline": "12px"
          }
        }, [_vm._v(" Tujuan Duplikasi ")]), _c('c-box', {
          attrs: {
            "padding-inline": "12px"
          }
        }, [_c('ChipDays', {
          attrs: {
            "start-at": 1,
            "end-at": 10,
            "selected-day": _vm.destinationDay,
            "custom-template-columns": "repeat(10, 1fr)",
            "custom-item-min-width": "36px",
            "checked-days": _vm.checkedDestinationDays
          },
          on: {
            "on-change-day": function onChangeDay(day) {
              return _vm.onChangeDestinationDay(day);
            }
          }
        })], 1), _c('c-box', {
          attrs: {
            "width": "100%",
            "max-height": "350px",
            "overflow-y": "auto",
            "padding-inline": "12px"
          }
        }, [_c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "gap": "16px"
          }
        }, _vm._l(_vm.mealTimeStates_, function (mealTime) {
          return _c('c-flex', {
            key: mealTime.key,
            attrs: {
              "justify-content": "space-between",
              "align-items": "center",
              "height": "52px",
              "background-color": "primary.400",
              "padding": "8px 16px",
              "border-radius": "8px",
              "cursor": "pointer",
              "_hover": {
                backgroundColor: 'primary.500'
              }
            },
            on: {
              "click": function click($event) {
                return _vm.onClickDestinationMealTime(mealTime.key, _vm.destinationDay);
              }
            }
          }, [_c('c-text', {
            attrs: {
              "font-size": "18px",
              "font-weight": "500",
              "color": "#FFF"
            }
          }, [_vm._v(" " + _vm._s(_vm.constants.mealPlanValueToLabel[mealTime.key]) + " ")]), _c('c-flex', {
            attrs: {
              "background-color": "#FFF",
              "border-radius": "6px"
            }
          }, [_c('c-checkbox', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {
                  width: '30px',
                  height: '30px',
                  borderRadius: '4px',
                  borderColor: 'primary.400'
                },
                'input[type=checkbox]+div svg': {
                  width: '15px',
                  height: '15px'
                },
                'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {
                  color: 'primary.400',
                  backgroundColor: 'white'
                }
              },
              expression: "{\n                      'input[type=checkbox]+div, input[type=checkbox][aria-checked=mixed]+div': {\n                        width: '30px',\n                        height: '30px',\n                        borderRadius: '4px',\n                        borderColor: 'primary.400'\n                      },\n                      'input[type=checkbox]+div svg': {\n                        width: '15px',\n                        height: '15px',\n                      },\n                      'input[type=checkbox]:checked+div, input[type=checkbox][aria-checked=mixed]+div': {\n                        color: 'primary.400',\n                        backgroundColor: 'white',\n                      }\n                    }"
            }],
            attrs: {
              "is-checked": _vm.checkIsCheckedDestinationMealTime(mealTime.key, _vm.destinationDay),
              "variant-color": "primary",
              "pointer-events": "none"
            }
          })], 1)], 1);
        }), 1)], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-grid', {
          attrs: {
            "width": "100%",
            "padding-inline": "16px",
            "padding-block": "10px",
            "grid-template-columns": "calc(50% - 25px) 50px calc(50% - 25px)"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onCloseDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('c-box'), _c('BaseButton', {
          attrs: {
            "color": "secondary",
            "is-disabled": _vm.isDisabledSubmit || _vm.isLoadingData,
            "is-loading": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onSubmitDuplicate
          }
        }, [_c('c-text', {
          attrs: {
            "color": "primary.400"
          }
        }, [_vm._v(" Simpan ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenErrorDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-warning.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "margin-top": "8px",
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Duplikasi jam makan gagal ")]), _vm.errorMessage ? _c('BaseText', {
          attrs: {
            "margin-top": "16px",
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px auto",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click() {
              _vm.onCloseDuplicate();

              _vm.isOpenErrorDuplicate = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenSuccessDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/success-image.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "margin-top": "8px",
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Duplikasi jam makan berhasil ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px auto",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click() {
              _vm.onCloseDuplicate();

              _vm.isOpenSuccessDuplicate = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }